<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">{{ $store.getters['languageMixin/getStringFromLanguage'](lang.label) }} {{ $store.getters['languageMixin/getStringFromLanguage']('Section') }}</h5>


      <!--          Content-->
      <div class="form-group">
        <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Content') }}</label>
        <editor
          v-model="formData.content"
          :api-key="editorKey"
          :init='editorConfig'
        />
      </div>

    </form>
  </ValidationObserver>
</template>
<script>
  import { Select, Option } from 'element-ui'
  import { extend } from "vee-validate";
  import { required, email, regex } from "vee-validate/dist/rules";
  import Editor from '@tinymce/tinymce-vue'
  import FormGroupTextArea from "@/components";

  extend("required", required);
  extend("email", email);
  extend("regex", regex);

  export default {
    components: {
      FormGroupTextArea,
      [Select.name]: Select,
      [Option.name]: Option,
      'editor': Editor
    },
    props: {
      lang: {
        type: Object
      },
      editMode: {
        type: Boolean,
        default: false
      },
      isSubmit: {
        type: Boolean,
        default: false
      },
      formData:{
        type: Object,
        default() {
          return {
            content:"",
          }
        }
      },
    },
    data () {
      return {
        editorConfig: this.getEditorConfig(),
        editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          if (res){
            this.$emit('submit-step', this.formData, this.lang.value, this.isSubmit)
          }else{
            this.$emit('on-validated', res)
          }
          return res
        });
      },
      addNewItem(listItems, initObject) {
        listItems.push(initObject);
      },
      removeItem(listItems, index) {
        listItems.splice(index, 1);
      },
    }
  }
</script>
<style>
</style>
